import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { useInView } from 'react-intersection-observer'
import { Layout, Footer } from '../components'

import { container, padding, margin, bgImage, colours, type, wrapper, pagePaddingTop, textOffset, rolloverAnimation } from '../styles/global'
import { parseACF } from '../utils/utils'
import { media } from '../styles/utils'

const CaseStudies = (props) => {
	const graphData = useStaticQuery(query)
	const caseStudies = graphData?.allWordpressProjects?.nodes
    const data = parseACF(graphData, 'allWordpressInfopages', true)
    
    const renderCaseStudy = (item, i) => {
        if (!item) return null;

        item = {
            ...item,
            ...JSON.parse(item.acf_json)
        }
    
        return (
            <CaseStudy
                key={i}
                to={`/case-studies/${item.slug}`}
            >
                {item.hero_image && (
                    <ImageWrapper>
                        <Image
                            key={item.hero_image?.sizes?.medium2}
                            src={item.hero_image?.sizes?.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage
                                        image={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    </ImageWrapper>
                )}
    
                <Info>
                    <Subheading>{item.sector}</Subheading>
    
                    <Heading>
                        {item.title}.<span>{item.location}</span>
                    </Heading>
    
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: item.listing_description,
                        }}
                    />
                </Info>
            </CaseStudy>
        )
    }
    

	const renderGrid = () => {
		if (!caseStudies) return;
		
		const items = caseStudies.map((item, i) => {
            return renderCaseStudy(item, i)
        })

		return (
			<Grid>
				{items}
			</Grid>
		)
	}

	return (
		<Layout	
            meta={data && data.seo}
            logoTheme="dark"
        >
			<Wrapper>
				<Container>					
					{renderGrid()}
				</Container>
			</Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const ImageWrapper = styled.div`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
	${wrapper}
	display: flex;
	flex-direction: column;
	width: 100%;
`

const Container = styled.div`
	${container}
	${padding}
    padding-top: 170px;

    ${media.phone`
        padding-top: 64px;
    `}

	> ${Heading} {
		${type.heading2};
	}
`

// Grid

const Grid = styled.div`
	display: flex;
	flex-flow: row wrap;
	margin-top: 42px;
	width: calc(100% + 40px);

    ${media.phone`
        margin-top: 55px;
    `}
`

const CaseStudy = styled(Link)`
	flex-basis: calc(50% - 40px);
	margin-right: 40px;
    margin-bottom: 64px;
    overflow: hidden;
    
    &:hover,
    &:focus {
        ${BGImage} {
            transform: scale(1.05);
        }
    }

	${BGImage} {
		width: 100%;
        padding-bottom: 62.5%;
        transition: transform 400ms ease-in-out;
	}

	${Info} {
		padding-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        ${media.phone`
            padding-top: 15px;
        `}

		${Subheading} {
			${type.caption}
			color: ${colours.darkRed};
			margin-bottom: 16px;
            ${rolloverAnimation};

            ${media.phone`
                font-size: 15px;
                margin-bottom: 0;
            `}
		}

		${Heading},
		${Description} {
			${type.body}
            max-width: 520px;
		}

		${Heading} {
			color: black;
			
			span {
				color: ${colours.lightGray};
                transition: color 0.3s ease-in-out;
				margin-left: 4px;
			}
		}

		${Description} {
			${textOffset}
			color: ${colours.midGray}
		}
    }

    &:hover {
        ${Subheading}::after {
            transform: none;
        }

        ${Heading} {
            span {
                color: black;
            }
        }
    }

    ${media.phone`
        flex-basis: inherit;
        margin-bottom: 105px;
        width: 100%;
    `}
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "case-studies" } }) {
            nodes {
				title
                acf_json
            }
		}
		
		allWordpressProjects {
			nodes {
				title
				slug
				acf_json
			}
		}
    }
`

export default CaseStudies
